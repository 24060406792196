import PropTypes from "prop-types";
import { connect } from "react-redux";
import Card from "../../components/card";
import TopCard, { TopCardLoader } from "../../components/top-card";
import { Icon } from "@iconify/react";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL, USER_CLIENT_URL } from "../../config/keys";
import React from "react";
import date_formatter from "../../utils/dateFormatter";
import nFormatter from "../../utils/nFormatter";
import SkeletonLoader from "tiny-skeleton-loader-react";
import Table from "../../utils/table";
import isEmpty from "is-empty";

const EmployeeHome = ({ auth }) => {
  const [overview, setOverview] = useState({});
  const [overviewLoading, setOverviewLoading] = useState(true);
  const [transaction, setTransaction] = useState([]);
  const [transactionLoading, setTransactionLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  useEffect(() => {
    axios
      .post(`${API_URL}/admin/api/transactions/overview/employee`)
      .then((response) => {
        setOverview(response.data);
        setOverviewLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to get transactions overview");
      });

    axios
      .post(`${API_URL}/admin/api/transactions/all/employee`)
      .then((response) => {
        setTransaction(response.data);
        setTransactionLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to fetch transactions history");
      });
  }, []);

  useEffect(() => {
    if (!isEmpty(dateFrom) && !isEmpty(dateTo)) {
      setTransactionLoading(true);

      axios
        .post(`${API_URL}/admin/api/transactions/all/employee`, {
          filter: {
            dateFrom,
            dateTo,
          },
        })
        .then((response) => {
          setTransaction(response.data);
          setTransactionLoading(false);
        })
        .catch((err) => {
          toast.error("Unable to fetch transactions history");
        });
    }
  }, [dateFrom, dateTo]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Reference",
        accessor: "reference",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Business Name",
        accessor: "biz",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    let dd = [];
    for (let i = 0; i < transaction.length; i++) {
      const e = transaction[i];
      dd.push({
        id: i,
        reference: e.reference,
        name: `${e.first_name} ${e.last_name}`,
        biz: e.business_name,
        date: date_formatter(e.paid_at),
        amount: `N ${nFormatter(e.amount / 100)}`,
      });
    }
    return dd;
  }, [transaction]);

  return (
    <div>
      <div
        className="uk-child-width-1-4@l uk-child-width-1-2@s uk-child-width-1-1 uk-grid-small uk-grid-match"
        data-uk-grid
      >
        {overviewLoading ? (
          <>
            <TopCardLoader />
            <TopCardLoader />
            <TopCardLoader />
          </>
        ) : (
          <>
            <TopCard
              title="Today"
              value={overview.today_sum}
              count={overview.today_count}
            />
            <TopCard
              title="This week"
              value={overview.week_sum}
              count={overview.week_count}
            />
            <TopCard
              title="This Month"
              value={overview.month_sum}
              count={overview.month_count}
            />
          </>
        )}
      </div>

      <Card className="uk-margin-top">
        <div className="uk-grid-small" data-uk-grid>
          <div className="uk-width-expand">
            <h4>
              Payment for {auth.user.channel_name} ({auth.user.purpose})
            </h4>
          </div>
          <div className="uk-width-auto">
            <CopyToClipboard
              text={`${USER_CLIENT_URL}/${auth.user.payment_link}`}
              onCopy={() => toast.success("Copied to clipboard")}
            >
              <pre className="border-rounded" style={{ cursor: "pointer" }}>
                <code>
                  {USER_CLIENT_URL}/{auth.user.payment_link}
                </code>{" "}
                <Icon icon="akar-icons:copy" />
              </pre>
            </CopyToClipboard>
          </div>
        </div>

        <div className="uk-margin-medium-top">
          <h5 className="uk-margin-small-bottom">Choose range</h5>
          <div className="uk-grid-small" data-uk-grid>
            <div className="uk-width-small">
              <input
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
                className="uk-input border-rounded"
              />
            </div>
            <div className="uk-width-small">
              <input
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
                className="uk-input border-rounded"
              />
            </div>
          </div>
        </div>

        {transactionLoading ? (
          <table className="uk-table uk-table-divider">
            <thead>
              <th>Customer</th>
              <th>Amount</th>
              <th>Date</th>
            </thead>

            <tbody>
              <tr>
                <td>
                  <SkeletonLoader />
                </td>
                <td>
                  <SkeletonLoader />
                </td>
                <td>
                  <SkeletonLoader />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div className="uk-overflow-auto">
            <Table columns={columns} data={data} />
          </div>
        )}
      </Card>
    </div>
  );
};

EmployeeHome.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(EmployeeHome);
