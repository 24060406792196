const Card = ({ children, type = "default", className }) => {
  return (
    <div
      className={`uk-card uk-card-${type} uk-border-rounded uk-card-small ${className}`}
      style={{ zIndex: 0, position: "relative" }}
    >
      <div className="uk-card-body">{children}</div>
    </div>
  );
};

export default Card;
