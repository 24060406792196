import isEmpty from "is-empty";
import { SET_CURRENT_USER, SET_LOADING } from "../actions/types";

const initialState = {
  isAuthenticated: false,
  loading: false,
  user: {},
};

export default function foo(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}
