import nFormatter from "../utils/nFormatter";
import numberWithCommas from "../utils/thousand";
import Card from "./card";
import SkeletonLoader from "tiny-skeleton-loader-react";

const TopCard = ({ title, value, count }) => {
  return (
    <li className="uk-width-4-5 uk-width-1-4@m uk-width-1-4@l uk-width-1-2@s absolute">
      <Card>
        <p className="uk-margin-remove-top uk-margin-small-bottom">{title}</p>
        <h1 className="uk-margin-remove">₦ {nFormatter(value / 100)}</h1>
        <p className="uk-margin-remove">
          {value / 100 >= 1000000 && (
            <>(NGN {numberWithCommas(value / 100)}) - </>
          )}
          <b>{count}</b> txns
        </p>
      </Card>
    </li>
  );
};

export const TopCardLoader = () => {
  return (
    <li className="uk-width-4-5 uk-width-1-4@m uk-width-1-4@l uk-width-1-2@s">
      <Card>
        <div className="uk-margin-remove-top uk-margin-small-bottom">
          <SkeletonLoader width={200} />
        </div>
        <h1 className="uk-margin-remove-top uk-margin-small-bottom">
          <SkeletonLoader width={150} />
        </h1>
        <div className="uk-margin-remove">
          <SkeletonLoader width={100} />
        </div>
      </Card>
    </li>
  );
};

export const TopCardUser = ({ title, value, previous }) => {
  return (
    <li className="uk-width-4-5 uk-width-1-4@m uk-width-1-4@l uk-width-1-2@s">
      <Card>
        <p className="uk-margin-remove-top uk-margin-small-bottom">{title}</p>
        <h1 className="uk-margin-remove">{value}</h1>
        <p className="uk-margin-remove uk-text-small">{previous}</p>
      </Card>
    </li>
  );
};

export default TopCard;
