import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../redux/actions/authActions";
import { useEffect, useState } from "react";
import MyLoader from "../components/myLoader";
import { logoBlack } from "../images";
import isEmpty from "is-empty";
import Validator from "validator";
import styles from "../styles/login.module.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../config/keys";
import toast from "react-hot-toast";

const LoginPage = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState({});

  useEffect(() => {
    isEmpty(email) || isEmpty(password) || !Validator.isEmail(email)
      ? setDisabled(true)
      : setDisabled(false);
  }, [email, password]);

  const history = useHistory();

  useEffect(() => {
    props.auth.isAuthenticated && history.push("/");
  }, [props.auth.isAuthenticated, history]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    const data = { email, password };

    axios
      .post(`${API_URL}/admin/auth/login`, data)
      .then((response) => {
        props.loginUser(response.data.token);
        setLoading(false);
      })
      .catch((err) => {
        if (!!err.response) {
          setError(err.response.data);
        }
        toast.error("An error occurred while logging in");
        setLoading(false);
      });
  };

  return (
    <div className={styles.loginWrapper}>
      <div
        className={`uk-card uk-card-default uk-box-shadow-large uk-width-xlarge uk-border-rounded`}
      >
        <div className="uk-card-body uk-text-center">
          <img src={logoBlack} alt="logo" className={`uk-margin-large-top ${styles.logo}`} />

          <form
            className="uk-margin-xlarge-top uk-text-left"
            onSubmit={handleSubmit}
          >
            <div className="uk-margin">
              <input
                type="email"
                className={`uk-input uk-form-large uk-text-small border-rounded ${
                  !!error.email ? "uk-form-danger" : ""
                }`}
                placeholder="Email"
                autoComplete="username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {!!error.email && (
                <label className="uk-form-label uk-text-danger">
                  {error.email}
                </label>
              )}
            </div>

            <div className="uk-margin">
              <input
                type="password"
                className={`uk-input uk-form-large uk-text-small border-rounded ${
                  !!error.password ? "uk-form-danger" : ""
                }`}
                placeholder="Password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {!!error.password && (
                <label className="uk-form-label uk-text-danger">
                  {error.password}
                </label>
              )}
            </div>

            <div className="uk-margin">
              <button
                className={`uk-button uk-button-large border-rounded uk-width-1-1 ${
                  loading || disabled ? "uk-disabled" : "uk-button-primary"
                }`}
              >
                {loading ? <MyLoader /> : "Log in"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { loginUser })(LoginPage);
