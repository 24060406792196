import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import LoginPage from "../pages/login";
// import ResetTokenPage from "../pages/reset-token";
// import ResetPasswordPage from "../pages/reset-password";
import AddPaymentChannel from "../pages/super-admin/add-payment-channel";
import AddSubAdmin from "../pages/super-admin/add-sub-admin";
import Channel from "../pages/super-admin/channel";
import PaymentChannel from "../pages/super-admin/payment-channel";
import SubAdmin from "../pages/super-admin/sub-admin";
import HomePage from "../pages";
import Logout from "../pages/logout";
import MyLoader from "../components/myLoader";

import styles from "../styles/layout.module.css";
import SubAdminIndividual from "../pages/super-admin/sub-admin-individual";
import Users from "../pages/super-admin/users";
import UserPage from "../pages/super-admin/user";

const AllRoutes = ({ auth }) => {
  return (
    <>
      {auth.loading && (
        <div className={styles.fullLoader}>
          <MyLoader />
        </div>
      )}
      <Router>
        <Route component={ScrollToTop} />
        <Switch>
          {/* <Route exact path="/" component={EmployeeHome} /> */}
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute exact path="/users" component={Users} />
          <PrivateRoute exact path="/users/:id" component={UserPage} />
          <PrivateRoute exact path="/subadmins" component={SubAdmin} />
          <PrivateRoute
            exact
            path="/subadmins/:id"
            component={SubAdminIndividual}
          />
          <PrivateRoute exact path="/subadmins/add" component={AddSubAdmin} />
          <PrivateRoute
            exact
            path="/payment-channel"
            component={PaymentChannel}
          />
          <PrivateRoute
            exact
            path="/payment-channel/add"
            component={AddPaymentChannel}
          />
          <PrivateRoute exact path="/payment-channel/:id" component={Channel} />
          <Route exact path="/login" component={LoginPage} />
          {/* removed reset password for admin */}
          {/* <Route exact path="/reset-token" component={ResetTokenPage} />
          <Route exact path="/reset-password" component={ResetPasswordPage} /> */}
          <Route exact path="/logout" component={Logout} />
        </Switch>
      </Router>
    </>
  );
};

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

AllRoutes.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AllRoutes);
