import PropTypes from "prop-types";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutUser } from "../redux/actions/authActions";

const Logout = (props) => {
  const history = useHistory();
  useEffect(() => {
    props.logoutUser();
    history.push("/login");
  });

  return (
    <div>
      <h1>Logged out</h1>
    </div>
  );
};

Logout.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Logout);
