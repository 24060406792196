import axios from "axios";
import isEmpty from "is-empty";
import React from "react";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import { API_URL } from "../../config/keys";
import nFormatter from "../../utils/nFormatter";
import Table from "../../utils/table";
import Card from "../card";

const RecentTransactions = () => {
  const [transaction, setTransaction] = useState([]);
  const [transactionLoading, setTransactionLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  useEffect(() => {
    axios
      .post(`${API_URL}/admin/api/transactions/all`)
      .then((response) => {
        setTransaction(response.data);
        setTransactionLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to fetch transactions history");
      });
  }, []);

  useEffect(() => {
    if (!isEmpty(dateFrom) && !isEmpty(dateTo)) {
      setTransactionLoading(true);

      axios
        .post(`${API_URL}/admin/api/transactions/all`, {
          filter: {
            dateFrom,
            dateTo,
          },
        })
        .then((response) => {
          setTransaction(response.data);
          setTransactionLoading(false);
        })
        .catch((err) => {
          toast.error("Unable to fetch transactions history");
        });
    }
  }, [dateFrom, dateTo]);

  // For recent transactions *******************************
  const columns = React.useMemo(
    () => [
      {
        Header: "Channel Name",
        accessor: "channel_name",
      },
      {
        Header: "Amount (N)",
        accessor: "amount",
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    let dd = [];
    for (let i = 0; i < transaction.length; i++) {
      const e = transaction[i];
      dd.push({
        id: i,
        channel_name: e.channel_name,
        amount: `N${nFormatter(e.amount / 100)}`,
      });
    }
    return dd;
  }, [transaction]);

  return (
    <Card>
      <div className="uk-grid-small" data-uk-grid>
        <div className="uk-width-expand">
          <h4>Recent transactions</h4>
        </div>
        <div className="uk-width-auto">
          <CSVLink
            data={data}
            filename={`recent_transactions_${new Date()}.csv`}
          >
            <button
              type="button"
              className="uk-button uk-button-primary uk-button-small uk-border-rounded"
            >
              Export to CSV
            </button>
          </CSVLink>
        </div>
      </div>

      <div className="uk-margin-medium-top">
        {transactionLoading ? (
          "Loading..."
        ) : (
          <Table columns={columns} data={data} />
        )}
      </div>

      <hr className="uk-margin-top uk-margin-bottom" />

      <div>
        <h5 className="uk-margin-small-bottom">Choose range</h5>
        <div className="uk-grid-small" data-uk-grid>
          <div className="uk-width-small">
            <input
              type="date"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
              className="uk-input border-rounded"
            />
          </div>
          <div className="uk-width-small">
            <input
              type="date"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
              className="uk-input border-rounded"
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default RecentTransactions;
