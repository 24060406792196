import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SkeletonLoader from "tiny-skeleton-loader-react";
import { API_URL } from "../../config/keys";
import { AddPaymentChannelModal } from "../../pages/super-admin/add-payment-channel";
import nFormatter from "../../utils/nFormatter";
import Table from "../../utils/table";
import Card from "../card";

const ChannelTransactions = ({ auth }) => {
  const [addModal, setAddModal] = useState(false);
  const [channelTransactions, setChannelTransactions] = useState([]);
  const [channelTransactionsLoading, setChannelTransactionsLoading] =
    useState(true);

  useEffect(() => {
    axios
      .post(`${API_URL}/admin/api/transactions/channel`)
      .then((response) => {
        setChannelTransactions(response.data);
        setChannelTransactionsLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to get channel transaction history");
      });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Channel Name",
        accessor: "channel_name",
      },
      {
        Header: "Amount (N)",
        accessor: "amount",
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    let dd = [];
    for (let i = 0; i < channelTransactions.length; i++) {
      const e = channelTransactions[i];
      dd.push({
        id: i,
        channel_name: e.channel_name,
        amount: `N${nFormatter(e.amount / 100)}`,
        link: `/payment-channel/${e.payment_link}`,
      });
    }
    return dd;
  }, [channelTransactions]);

  return (
    <Card>
      <div className="uk-grid-small" data-uk-grid>
        <div className="uk-width-expand">
          <h4 className="uk-margin-remove-bottom">Payment Channel</h4>
          <p className="uk-margin-remove-top uk-text-meta">
            Total amount made by payment channels TODAY!
          </p>
        </div>
        {auth.user.role === "Super Admin" && (
          <div className="uk-width-auto">
            <button
              type="button"
              onClick={() => setAddModal(true)}
              className="uk-button uk-button-primary uk-button-small uk-border-rounded"
            >
              New
            </button>
          </div>
        )}
      </div>

      <div className="uk-margin-medium-top">
        {channelTransactionsLoading ? (
          <table className="uk-table uk-table-divider">
            <thead>
              <th>Channel name</th>
              <th>Amount (N)</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <SkeletonLoader />
                </td>
                <td>
                  <SkeletonLoader />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <Table columns={columns} data={data} />
        )}
      </div>

      {addModal && <AddPaymentChannelModal set={setAddModal} />}
    </Card>
  );
};

export default ChannelTransactions;
