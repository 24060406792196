import axios from "axios";
import isEmpty from "is-empty";
import { useEffect, useState } from "react";
import MyModal from "../../components/modal";
import MyLoader from "../../components/myLoader";
import { API_URL } from "../../config/keys";
import toast from "react-hot-toast";

const EditPaymentChannel = ({ set, success, getLatest, value }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [details, setDetails] = useState({
    id: value.id,
    cashierName: value.fullName,
    channelName: value.channel_name,
    paymentLink: value.payment_link,
    email: value.email,
    address: value.address,
    purpose: value.purpose,
    alert: value.alert_message,
    phone: value.sms,
    password: "",
  });

  useEffect(() => {
    if (
      isEmpty(details.cashierName) ||
      isEmpty(details.channelName) ||
      isEmpty(details.paymentLink) ||
      isEmpty(details.email) ||
      isEmpty(details.address) ||
      isEmpty(details.purpose) ||
      isEmpty(details.alert) ||
      isEmpty(details.phone) ||
      (!!error.paymentLink && !error.paymentLink.type)
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [details, error.paymentLink]);

  const onUpdate = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const checkPaymentLink = (e) => {
    if (e === value.payment_link) {
      setError({
        ...error,
        paymentLink: {
          type: true,
          value: "",
        },
      });
    } else {
      setError({
        ...error,
        paymentLink: {
          type: false,
          value: "Loading...",
        },
      });

      axios
        .post(`${API_URL}/admin/api/get/payment-channel/payment-link`, {
          paymentLink: e,
        })
        .then((response) => {
          setError({
            ...error,
            paymentLink: response.data,
          });
        })
        .catch((error) => {
          if (!!error.response) {
            setError({
              ...error,
              paymentLink: error.response.data.paymentLink,
            });
          } else {
            toast.error("Unable to check payment link availability");
          }
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${API_URL}/admin/api/update/payment-channel`, details)
      .then((response) => {
        !!getLatest &&
          getLatest({
            channel_name: details.channelName,
            payment_link: details.paymentLink,
            fullName: details.cashierName,
            purpose: details.purpose,
          });
        toast.success("Updated successfully");
        setLoading(false);
        !!success && success();
      })
      .catch((err) => {
        setLoading(false);

        if (!!err.response) {
          setError(err.response.data);

          if (!!err.response.data.error) {
            toast.error(err.response.data.error);
          }
        } else {
          toast.error("Unable to update payment channel");
        }
      });
  };

  return (
    <div className="uk-width-large uk-margin-top uk-margin-auto">
      <h3 className="uk-margin-medium-bottom">Update payment channel</h3>

      <form className={loading ? "uk-disabled" : ""} onSubmit={handleSubmit}>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="cashierName">
            Cashier Name
          </label>
          <input
            type="text"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.cashierName ? "uk-form-danger" : ""
            }`}
            id="cashierName"
            name="cashierName"
            value={details.cashierName}
            onChange={onUpdate}
            placeholder="Cashier Name"
          />
          {!!error.cashierName && (
            <label className="uk-text-danger">{error.cashierName}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="channelName">
            Channel Name
          </label>
          <input
            type="text"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.channelName ? "uk-form-danger" : ""
            }`}
            id="channelName"
            name="channelName"
            value={details.channelName}
            onChange={onUpdate}
            placeholder="Channel Name"
          />
          {!!error.channelName && (
            <label className="uk-text-danger">{error.channelName}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="paymentLink">
            Payment link
          </label>
          <input
            type="text"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.paymentLink
                ? error.paymentLink.type
                  ? "uk-form-success"
                  : "uk-form-danger"
                : ""
            }`}
            id="paymentLink"
            name="paymentLink"
            value={details.paymentLink}
            autoComplete="off"
            onChange={onUpdate}
            onChangeCapture={(e) => checkPaymentLink(e.target.value)}
            placeholder="Payment link"
          />
          {!!error.paymentLink && (
            <label
              className={
                error.paymentLink.type ? "uk-text-success" : "uk-text-danger"
              }
            >
              {error.paymentLink.value}
            </label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="address">
            Address
          </label>
          <input
            type="text"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.address ? "uk-form-danger" : ""
            }`}
            id="address"
            name="address"
            value={details.address}
            onChange={onUpdate}
            placeholder="Address"
          />
          {!!error.address && (
            <label className="uk-text-danger">{error.address}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="purpose">
            Purpose
          </label>
          <input
            type="text"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.purpose ? "uk-form-danger" : ""
            }`}
            id="purpose"
            name="purpose"
            value={details.purpose}
            onChange={onUpdate}
            placeholder="Purpose"
          />
          {!!error.purpose && (
            <label className="uk-text-danger">{error.purpose}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="phone">
            Cashier SMS line
          </label>
          <input
            type="tel"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.phone ? "uk-form-danger" : ""
            }`}
            id="phone"
            name="phone"
            value={details.phone}
            onChange={onUpdate}
            placeholder="Cashier SMS line"
          />
          {!!error.phone && (
            <label className="uk-text-danger">{error.phone}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="alert">
            Alert Message
          </label>
          <textarea
            rows={4}
            className="uk-textarea border-rounded uk-form-large uk-text-small"
            id="alert"
            name="alert"
            value={details.alert}
            onChange={onUpdate}
            placeholder="Alert message"
          />
          {!!error.alert && (
            <label className="uk-text-danger">{error.alert}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.email ? "uk-form-danger" : ""
            }`}
            id="email"
            name="email"
            autoComplete="username"
            value={details.email}
            onChange={onUpdate}
            placeholder="Email"
          />
          {!!error.email && (
            <label className="uk-text-danger">{error.email}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.password ? "uk-form-danger" : ""
            }`}
            id="password"
            name="password"
            autoComplete="new-password"
            value={details.password}
            onChange={onUpdate}
            placeholder="Password"
          />
          {!!error.password && (
            <label className="uk-text-danger">{error.password}</label>
          )}
        </div>

        <div>
          <div className="uk-grid-small uk-child-width-expand" data-uk-grid>
            {!!set && (
              <div>
                <button
                  type="button"
                  onClick={set}
                  className="uk-button uk-button-default border-rounded uk-button-large uk-width-1-1"
                >
                  Cancel
                </button>
              </div>
            )}
            <div>
              <button
                type="submit"
                className={`uk-button ${
                  isDisabled || loading ? "uk-disabled" : "uk-button-primary"
                } border-rounded uk-button-large uk-width-1-1`}
              >
                {loading ? <MyLoader /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export const EditPaymentChannelModal = ({ set, getLatest, value }) => {
  const [open, setOpen] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);

  const close = () => {
    setConfirmModal(true);
  };

  const cancelAdd = () => {
    setConfirmModal(false);
    setOpen(false);
    set(false);
  };

  return (
    <>
      <MyModal open={open} close={close}>
        <EditPaymentChannel
          set={close}
          success={cancelAdd}
          getLatest={getLatest}
          value={value}
        />
      </MyModal>

      <MyModal open={confirmModal}>
        <div className="uk-width-medium">
          <h5>Are you sure you want to close this modal?</h5>

          <div>
            <div className="uk-grid-small uk-child-width-expand" data-uk-grid>
              <div>
                <button
                  type="button"
                  onClick={() => setConfirmModal(false)}
                  className="uk-button uk-button-primary border-rounded uk-button-large uk-width-1-1"
                >
                  No
                </button>
              </div>

              <div>
                <button
                  type="button"
                  onClick={cancelAdd}
                  className="uk-button uk-button-danger border-rounded uk-button-large uk-width-1-1"
                >
                  Discard
                </button>
              </div>
            </div>
          </div>
        </div>
      </MyModal>
    </>
  );
};

export default EditPaymentChannel;
