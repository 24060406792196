import axios from "axios";
import isEmpty from "is-empty";
import { useEffect, useState } from "react";
import MyModal from "../../components/modal";
import MyLoader from "../../components/myLoader";
import { API_URL } from "../../config/keys";
import toast from "react-hot-toast";

const EditUserAccount = ({ set, success, getLatest, value }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [details, setDetails] = useState({
    id: value.id,
    firstName: value.first_name,
    lastName: value.last_name,
    businessName: value.business_name,
    email: value.email,
    phone: value.phone_number,
  });

  useEffect(() => {
    if (
      isEmpty(details.firstName) ||
      isEmpty(details.lastName) ||
      isEmpty(details.businessName) ||
      isEmpty(details.email) ||
      isEmpty(details.phone)
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [details, error.paymentLink]);

  const onUpdate = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${API_URL}/admin/api/update/user`, details)
      .then((response) => {
        !!getLatest &&
          getLatest({
            first_name: details.firstName,
            last_name: details.lastName,
            phone_number: details.phone,
            email: details.email,
            business_name: details.businessName,
          });
            toast.success("Updated successfully");
            setLoading(false);
            !!success && success();

      })
      .catch((err) => {
        setLoading(false);

        if (!!err.response) {
          setError(err.response.data);

          if (!!err.response.data.error) {
            toast.error(err.response.data.error);
          }
        } else {
          toast.error("Unable to user profile");
        }
      });
  };

  return (
    <div className="uk-width-large uk-margin-top uk-margin-auto">
      <h3 className="uk-margin-medium-bottom">Update user account</h3>

      <form className={loading ? "uk-disabled" : ""} onSubmit={handleSubmit}>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="firstName">
            First Name
          </label>
          <input
            type="text"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.firstName ? "uk-form-danger" : ""
            }`}
            id="firstName"
            name="firstName"
            value={details.firstName}
            onChange={onUpdate}
            placeholder="First Name"
          />
          {!!error.firstName && (
            <label className="uk-text-danger">{error.firstName}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="lastName">
            Last Name
          </label>
          <input
            type="text"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.lastName ? "uk-form-danger" : ""
            }`}
            id="lastName"
            name="lastName"
            value={details.lastName}
            onChange={onUpdate}
            placeholder="Last Name"
          />
          {!!error.lastName && (
            <label className="uk-text-danger">{error.lastName}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.email ? "uk-form-danger" : ""
            }`}
            id="email"
            name="email"
            autoComplete="username"
            value={details.email}
            onChange={onUpdate}
            placeholder="Email"
          />
          {!!error.email && (
            <label className="uk-text-danger">{error.email}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="businessName">
            Business Name
          </label>
          <input
            type="text"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.businessName ? "uk-form-danger" : ""
            }`}
            id="businessName"
            name="businessName"
            value={details.businessName}
            onChange={onUpdate}
            placeholder="Business Name"
          />
          {!!error.businessName && (
            <label className="uk-text-danger">{error.businessName}</label>
          )}
        </div>


        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="phone">
            Phone
          </label>
          <input
            type="tel"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.phone ? "uk-form-danger" : ""
            }`}
            id="phone"
            name="phone"
            value={details.phone}
            onChange={onUpdate}
            placeholder="Phone"
          />
          {!!error.phone && (
            <label className="uk-text-danger">{error.phone}</label>
          )}
        </div>


        <div>
          <div className="uk-grid-small uk-child-width-expand" data-uk-grid>
            {!!set && (
              <div>
                <button
                  type="button"
                  onClick={set}
                  className="uk-button uk-button-default border-rounded uk-button-large uk-width-1-1"
                >
                  Cancel
                </button>
              </div>
            )}
            <div>
              <button
                type="submit"
                className={`uk-button ${
                  isDisabled || loading ? "uk-disabled" : "uk-button-primary"
                } border-rounded uk-button-large uk-width-1-1`}
              >
                {loading ? <MyLoader /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export const EditUserAccountModal = ({ set, getLatest, value }) => {
  const [open, setOpen] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);

  const close = () => {
    setConfirmModal(true);
  };

  const cancelAdd = () => {
    setConfirmModal(false);
    setOpen(false);
    set(false);
  };

  return (
    <>
      <MyModal open={open} close={close}>
        <EditUserAccount
          set={close}
          success={cancelAdd}
          getLatest={getLatest}
          value={value}
        />
      </MyModal>

      <MyModal open={confirmModal}>
        <div className="uk-width-medium">
          <h5>Are you sure you want to close this modal?</h5>

          <div>
            <div className="uk-grid-small uk-child-width-expand" data-uk-grid>
              <div>
                <button
                  type="button"
                  onClick={() => setConfirmModal(false)}
                  className="uk-button uk-button-primary border-rounded uk-button-large uk-width-1-1"
                >
                  No
                </button>
              </div>

              <div>
                <button
                  type="button"
                  onClick={cancelAdd}
                  className="uk-button uk-button-danger border-rounded uk-button-large uk-width-1-1"
                >
                  Discard
                </button>
              </div>
            </div>
          </div>
        </div>
      </MyModal>
    </>
  );
};

export default EditUserAccountModal;
