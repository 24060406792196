import Sidebar from "./sidebar";
import styles from "../../styles/layout.module.css";
import HeaderComponent from "./header";
import { useEffect, useState } from "react";

const Layout = ({ children }) => {
  const [openSidebar, closeSidebar] = useState(true);

  useEffect(() => {
    if (window.screen.width < 640) {
      closeSidebar(false);
    }
  }, []);

  return (
    <div>
      <Sidebar open={openSidebar} />
      <div className={`${styles.main} ${!openSidebar ? styles.full : ""}`}>
        <HeaderComponent setSidebar={closeSidebar} open={openSidebar} />
        <div className="uk-container uk-margin-top">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
