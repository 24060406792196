import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TopCard, { TopCardLoader } from "../../components/top-card";
import axios from "axios";
import { API_URL } from "../../config/keys";
import toast from "react-hot-toast";
import React from "react";
import RecentTransactions from "../../components/pages/recent-transactions";
import ChannelTransactions from "../../components/pages/channel-transactions";

const SuperAdminHomePage = ({ auth }) => {
  const [overview, setOverview] = useState({});
  const [overviewLoading, setOverviewLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const acceptedRoles = ["Super Admin", "Admin"];

    !acceptedRoles.includes(auth.user.role) && history.push("/login");
  }, [auth.user.role, history]);

  useEffect(() => {
    axios
      .post(`${API_URL}/admin/api/transactions/overview`)
      .then((response) => {
        setOverview(response.data);
        setOverviewLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to get transactions overview");
      });
  }, []);

  return (
    <div>
      <div
        className="uk-position-relative uk-visible-toggle overflow-visible"
        tabIndex="-1"
        data-uk-slider="finite: true"
      >
        <ul className="uk-slider-items uk-grid-small uk-grid-match">
          {overviewLoading ? (
            <>
              <TopCardLoader />
              <TopCardLoader />
              <TopCardLoader />
              <TopCardLoader />
            </>
          ) : (
            <>
              <TopCard
                title="Today"
                value={overview.today_sum}
                count={overview.today_count}
              />
              <TopCard
                title="This week"
                value={overview.week_sum}
                count={overview.week_count}
              />
              <TopCard
                title="This Month"
                value={overview.month_sum}
                count={overview.month_count}
              />
              <TopCard
                title="All time"
                value={overview.alltime_sum}
                count={overview.alltime_count}
              />
            </>
          )}
        </ul>
      </div>

      <div
        className="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-small uk-margin-medium-top"
        data-uk-grid
      >
        <div>
          <ChannelTransactions auth={auth} />
        </div>

        <div>
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
};

SuperAdminHomePage.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SuperAdminHomePage);
