import Modal from "react-modal";
import styles from "../styles/modal.module.css";

Modal.setAppElement("#root");

const MyModal = ({ open, close, children }) => {
  return (
    <Modal
      isOpen={open}
      overlayClassName={styles.overlay}
      className={`uk-card uk-card-default uk-border-rounded uk-card-body uk-card-small uk-box-shadow-xlarge ${styles.body}`}
      preventScroll={true}
      onRequestClose={!!close ? close : null}
    >
      {children}
    </Modal>
  );
};

export default MyModal;
