import axios from "axios";
import isEmpty from "is-empty";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import MyModal from "../../components/modal";
import MyLoader from "../../components/myLoader";
import { API_URL } from "../../config/keys";

const AddSubAdmin = ({ set, success }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [details, setDetails] = useState({
    name: "",
    email: "",
    phone: "",
    whatsapp: "",
    password: "",
  });

  useEffect(() => {
    if (
      isEmpty(details.name) ||
      isEmpty(details.email) ||
      isEmpty(details.whatsapp) ||
      isEmpty(details.phone) ||
      isEmpty(details.password)
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [details]);

  const onUpdate = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${API_URL}/admin/api/add/subadmin`, details)
      .then((response) => {
        toast.success("Added successfully");
        !!success && success();
      })
      .catch((err) => {
        if (!!err.response) {
          setError(err.response.data);

          if (!!err.response.data.error) {
            toast.error(err.response.data.error);
          }
        } else {
          toast.error("Unable to add Sub Admin");
        }
        setLoading(false);
      });
  };

  return (
    <div className="uk-width-large uk-margin-top uk-margin-auto">
      <h3 className="uk-margin-medium-bottom">Add Sub Admin</h3>

      <form className={loading ? "uk-disabled" : ""} onSubmit={handleSubmit}>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.name ? "uk-form-danger" : ""
            }`}
            id="name"
            name="name"
            value={details.name}
            onChange={onUpdate}
            placeholder="Name"
          />
          {!!error.name && (
            <label className="uk-text-danger">{error.name}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.email ? "uk-form-danger" : ""
            }`}
            id="email"
            name="email"
            autoComplete="username"
            value={details.email}
            onChange={onUpdate}
            placeholder="Email"
          />
          {!!error.email && (
            <label className="uk-text-danger">{error.email}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="phone">
            Phone
          </label>
          <input
            type="tel"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.phone ? "uk-form-danger" : ""
            }`}
            id="phone"
            name="phone"
            value={details.phone}
            onChange={onUpdate}
            placeholder="Phone"
          />
          {!!error.phone && (
            <label className="uk-text-danger">{error.phone}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="whatsapp">
            WhatsApp
          </label>
          <input
            type="tel"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.whatsapp ? "uk-form-danger" : ""
            }`}
            id="whatsapp"
            name="whatsapp"
            value={details.whatsapp}
            onChange={onUpdate}
            placeholder="WhatsApp"
          />
          {!!error.whatsapp && (
            <label className="uk-text-danger">{error.whatsapp}</label>
          )}
        </div>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            className={`uk-input border-rounded uk-form-large uk-text-small ${
              !!error.password ? "uk-form-danger" : ""
            }`}
            id="password"
            name="password"
            autoComplete="new-password"
            value={details.password}
            onChange={onUpdate}
            placeholder="Password"
          />
          {!!error.password && (
            <label className="uk-text-danger">{error.password}</label>
          )}
        </div>

        <div>
          <div className="uk-grid-small uk-child-width-expand" data-uk-grid>
            {!!set && (
              <div>
                <button
                  type="button"
                  onClick={set}
                  className="uk-button uk-button-default border-rounded uk-button-large uk-width-1-1"
                >
                  Cancel
                </button>
              </div>
            )}
            <div>
              <button
                type="submit"
                className={`uk-button ${
                  isDisabled || loading ? "uk-disabled" : "uk-button-primary"
                } border-rounded uk-button-large uk-width-1-1`}
              >
                {loading ? <MyLoader /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export const AddSubAdminModal = ({ set }) => {
  const [open, setOpen] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);

  const close = () => {
    setConfirmModal(true);
  };

  const cancelAdd = () => {
    setConfirmModal(false);
    setOpen(false);
    set(false);
  };

  return (
    <>
      <MyModal open={open} close={close}>
        <AddSubAdmin set={close} success={cancelAdd} />
      </MyModal>

      <MyModal open={confirmModal}>
        <div className="uk-width-medium">
          <h5>Are you sure you want to close this modal?</h5>

          <div>
            <div className="uk-grid-small uk-child-width-expand" data-uk-grid>
              <div>
                <button
                  type="button"
                  onClick={() => setConfirmModal(false)}
                  className="uk-button uk-button-primary border-rounded uk-button-large uk-width-1-1"
                >
                  No
                </button>
              </div>

              <div>
                <button
                  type="button"
                  onClick={cancelAdd}
                  className="uk-button uk-button-danger border-rounded uk-button-large uk-width-1-1"
                >
                  Discard
                </button>
              </div>
            </div>
          </div>
        </div>
      </MyModal>
    </>
  );
};

export default AddSubAdmin;
