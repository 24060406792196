import { usePagination, useSortBy, useTable } from "react-table";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const Table = ({ columns, data, className = "uk-table uk-table-divider" }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      <table className={className} {...getTableProps()}>
        {headerGroups.map((headerGroup) => (
          <thead {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}{" "}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <Icon icon="codicon:triangle-down" inline={true} />
                    ) : (
                      <Icon icon="codicon:triangle-up" inline={true} />
                    )
                  ) : (
                    ""
                  )}
                </span>
              </th>
            ))}
          </thead>
        ))}
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (!!cell.row.original.link) {
                    return (
                      <td {...cell.getCellProps()} className="uk-table-link">
                        <Link
                          className="uk-link-reset"
                          to={cell.row.original.link}
                        >
                          {cell.render("Cell")}
                        </Link>
                      </td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
      <div className="pagination uk-margin-large-top" data-uk-grid>
        <div
          className="uk-width-expand@s uk-width-1-1 uk-text-center uk-text-left@s"
          style={{ minWidth: 200 }}
        >
          <button
            className="uk-button pagination uk-button-small uk-button-default"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <Icon icon="fluent:chevron-double-left-20-filled" inline={true} />
          </button>{" "}
          <button
            className="uk-button pagination uk-button-small uk-button-default"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <Icon icon="fluent:chevron-left-24-filled" inline={true} />
          </button>{" "}
          <button
            className="uk-button pagination uk-button-small uk-button-default"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <Icon icon="fluent:chevron-right-12-regular" inline={true} />
          </button>{" "}
          <button
            className="uk-button pagination uk-button-small uk-button-default"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <Icon icon="fluent:chevron-double-right-20-filled" inline={true} />
          </button>{" "}
          <span className="uk-text-meta">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
        </div>
        <div className="uk-width-auto@s uk-width-1-1 uk-text-center">
          <span>
            Go to page:{" "}
            <input
              type="number"
              defaultValue={1}
              className="uk-input uk-form-small pagination"
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
          </span>{" "}
          <select
            className="uk-select uk-form-small pagination"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default Table;
