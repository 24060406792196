import { useEffect, useState } from "react";
import { AddSubAdminModal } from "./add-sub-admin";
import axios from "axios";
import { API_URL } from "../../config/keys";
import toast from "react-hot-toast";
import Table from "../../utils/table";
import React from "react";

const SubAdmin = () => {
  const [addModal, setAddModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subadmin, setSubadmin] = useState([]);

  useEffect(() => {
    axios
      .post(`${API_URL}/admin/api/get/subadmins`)
      .then((response) => {
        setLoading(false);
        setSubadmin(response.data);
      })
      .catch((error) => {
        toast.error("Unable to get sub admins");
      });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "fullName",
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    let dd = [];
    for (let i = 0; i < subadmin.length; i++) {
      const e = subadmin[i];
      dd.push({
        id: i,
        fullName: e.fullName,
        link: `/subadmins/${e.id}`,
      });
    }
    return dd;
  }, [subadmin]);

  return (
    <div>
      <div className="uk-grid-small" data-uk-grid>
        <div className="uk-width-expand">
          <h3>Sub Admins</h3>
        </div>
        <div className="uk-width-auto">
          <button
            type="button"
            onClick={() => setAddModal(true)}
            className="uk-button uk-button-primary border-rounded"
          >
            New
          </button>
        </div>
      </div>

      <div className="uk-margin-medium-top">
        {loading ? (
          "Loading..."
        ) : (
          <Table
            columns={columns}
            data={data}
            className="uk-table uk-table-divider uk-table-hover uk-table-large"
          />
        )}
      </div>

      {addModal && <AddSubAdminModal set={setAddModal} />}
    </div>
  );
};

export default SubAdmin;
