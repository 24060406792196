import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
// import isEmpty from "is-empty";
import toast from "react-hot-toast";
import SkeletonLoader from "tiny-skeleton-loader-react";
import { API_URL } from "../../config/keys";
import date_formatter from "../../utils/dateFormatter";
import Table from "../../utils/table";
import { TopCardLoader, TopCardUser } from "../../components/top-card";

const Users = ({ auth }) => {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [overview, setOverview] = useState({});
  const [overviewLoading, setOverviewLoading] = useState(true);
  const [search, setSearch] = useState("");
  // const [loading, setLoading] = useState(false);
  // const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState({});

  const history = useHistory();

  // useEffect(() => {
  //   isEmpty(search) 
  //     ? setDisabled(true)
  //     : setDisabled(false);
  // }, [search]);

  useEffect(() => {
    const acceptedRoles = ["Super Admin", "Admin"];

    !acceptedRoles.includes(auth.user.role) && history.push("/");
  }, [auth.user.role, history]);

  useEffect(() => {
    axios
      .post(`${API_URL}/admin/api/users/overview`)
      .then((response) => {
        setOverview(response.data);
        setOverviewLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to get transactions overview");
      });

    axios
      .post(`${API_URL}/admin/api/users`)
      .then((response) => {
        setUsersLoading(false);
        setUsers(response.data);
      })
      .catch((error) => {
        toast.error("Unable to get all users");
      });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header:"Brand",
        accessor:"brand"
      },
      {
        Header:'Phone',
        accessor:"phone"
      },
      {
        Header:'email',
        accessor:'email'
      },
      {
        Header: "Date Joined",
        accessor: "date",
      },
      
    ],
    []
  );

  const data = React.useMemo(() => {
    let dd = [];
    for (let i = 0; i < users.length; i++) {
      const e = users[i];
      dd.push({
        id: i,
        name: `${e.first_name} ${e.last_name}`,
        email:e.email,
        phone:e.phone_number,
        brand:e.business_name,
        date: date_formatter(e.date_added),
        link: `/users/${e.id}`,
      });
    }
    return dd;
  }, [users]);

  const searchUser=(query)=>{
if(query.length===0 || query===" ")return data;
else{
  return data.filter((value)=>value.name.includes(query) || value.email.includes(query)|| value.phone.includes(query) || value.brand.includes(query))
}
}

  const handleSubmit = (e) => {
    e.preventDefault();

    // setLoading(true);
    const data = { search };

    axios
      .post(`${API_URL}/admin/auth/login`, data)
      .then((response) => {
        // setLoading(false);
      })
      .catch((err) => {
        if (!!err.response) {
          setError(err.response.data);
        }
        toast.error("An error occurred while logging in");
        // setLoading(false);
      });
  };

  return (
    <div>
      <h3>Users</h3>

      <div
        className="uk-position-relative uk-visible-toggle overflow-visible"
        tabIndex="-1"
        data-uk-slider="finite: true"
      >
        <ul className="uk-slider-items uk-grid-small uk-grid-match">
          {overviewLoading ? (
            <>
              <TopCardLoader />
              <TopCardLoader />
              <TopCardLoader />
              <TopCardLoader />
            </>
          ) : (
            <>
              <TopCardUser
                title="Today"
                value={overview.today_sum}
                previous={`Yesterday: ${overview.yesterday_sum}`}
              />
              <TopCardUser
                title="This week"
                value={overview.week_sum}
                previous={`Last week: ${overview.lastweek_sum}`}
              />
              <TopCardUser
                title="This Month"
                value={overview.month_sum}
                previous={`Last month: ${overview.lastmonth_sum}`}
              />
              <TopCardUser title="All time" value={overview.alltime_sum} />
            </>
          )}
        </ul>
      </div>
      {/* search form */}
      <form
            className="uk-margin-xlarge-top uk-text-left"
            onSubmit={handleSubmit}
          >
            <div className="uk-margin">
              <input
                type="text"
                className={`uk-input uk-form-large uk-text-small border-rounded ${
                  !!error.search ? "uk-form-danger" : ""
                }`}
                placeholder="Search"
                autoComplete=""
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {!!error.search && (
                <label className="uk-form-label uk-text-danger">
                  {error.search}
                </label>
              )}
            </div>

</form>

      <div className="uk-margin-medium-top">
        {usersLoading ? (
          <table className="uk-table uk-table-divider uk-table-large">
            <thead>
              <th>Name</th>
              <th>Date Joined</th>
            </thead>

            <tbody>
              <tr>
                <td>
                  <SkeletonLoader />
                </td>
                <td>
                  <SkeletonLoader />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <Table
            columns={columns}
            data={searchUser(search)}
            className="uk-table uk-table-divider uk-table-hover uk-table-large"
          />
        )}
      </div>
    </div>
  );
};

Users.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Users);
