import axios from "axios";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import {EditUserAccountModal } from "./edit-user-account";
import { useParams } from "react-router-dom";
import { API_URL } from "../../config/keys";
import date_formatter from "../../utils/dateFormatter";
import Table from "../../utils/table";
import numberWithCommas from "../../utils/thousand";
import { CSVLink } from "react-csv";

const UserPage = ({ auth }) => {
  const { id } = useParams();

  const [addModal, setAddModal] = useState(false);
  const [details, setDetails] = useState({});
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [overview, setOverview] = useState({});
  const [overviewLoading, setOverviewLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [t_loading, setT_loading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const acceptedRoles = ["Super Admin", "Admin"];

    !acceptedRoles.includes(auth.user.role) && history.push("/");
  }, [auth.user.role, history]);

  useEffect(() => {
    axios
      .post(`${API_URL}/admin/api/user`, { id })
      .then((response) => {
        setDetails(response.data);
        setDetailsLoading(false);
      })
      .catch((error) => {
        toast.error("Unable to load user profile");
      });

    axios
      .post(`${API_URL}/admin/api/transactions/overview/user`, { id })
      .then((response) => {
        setOverview(response.data);
        setOverviewLoading(false);
      })
      .catch((error) => {
        toast.error("Unable to load transaction overview");
      });

    axios
      .post(`${API_URL}/admin/api/transactions/all/user`, { id })
      .then((response) => {
        setTransactions(response.data);
        setT_loading(false);
      })
      .catch((error) => {
        toast.error("Unable to fetch transactions history");
      });
  }, [id]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Reference",
        accessor: "reference",
      },
      {
        Header: "Payment For",
        accessor: "payment_for",
      },
      {
        Header: "Amount (N)",
        accessor: "amount",
      },
      {
        Header: "Date",
        accessor: "date",
      },
    ],
    []
  );

  const getLatest = (e) => {
    setDetails({
      ...details,
      ...e,
    });
  };

  const data = React.useMemo(() => {
    let dd = [];
    for (let i = 0; i < transactions.length; i++) {
      const e = transactions[i];
      dd.push({
        id: i,
        reference: e.reference,
        payment_for: `${e.purpose}: ${e.channel_name}`,
        amount: numberWithCommas(e.amount / 100),
        date: date_formatter(e.paid_at),
      });
    }
    return dd;
  }, [transactions]);

  return (
    <div>
      <div
        className="uk-position-relative uk-visible-toggle overflow-visible"
        tabIndex="-1"
        data-uk-slider="finite: true"
      >
        <ul className="uk-slider-items uk-grid-small uk-grid-match">
          <li className="uk-width-4-5 uk-width-1-4@l uk-width-1-2@s">
            <div className="uk-card uk-card-default uk-card-small uk-border-rounded">
              <div className="uk-card-body">
                <p className="uk-margin-remove">Today's payment</p>
                <h1 className="uk-margin-small-top">
                  {overviewLoading ? "..." : overview.today_count}
                </h1>
                <p className="uk-margin-remove uk-text-small">
                  All time:{" "}
                  <b>{overviewLoading ? "..." : overview.alltime_count}</b>
                </p>
              </div>
            </div>
          </li>

          <li className="uk-width-4-5 uk-width-1-4@l uk-width-1-2@s">
            <div className="uk-card uk-card-default uk-card-small uk-border-rounded">
              <div className="uk-card-body">
                <p className="uk-margin-remove">Today's total</p>
                <h1 className="uk-margin-small-top">
                  N{" "}
                  {overviewLoading
                    ? "..."
                    : numberWithCommas(overview.today_sum / 100)}
                </h1>
                <p className="uk-margin-remove uk-text-small">
                  All time:{" "}
                  <b>
                    N{" "}
                    {overviewLoading
                      ? "..."
                      : numberWithCommas(overview.alltime_sum / 100)}
                  </b>
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div className="uk-margin-large">
      <div className="uk-grid-small" data-uk-grid>
          <div className="uk-width-expand">
          <h3>User Profile</h3>
          </div>
          <div className="uk-width-auto">
            {auth.user.role === "Super Admin" && (
              <button
                type="button"
                onClick={() => setAddModal(true)}
                className="uk-button uk-button-primary border-rounded"
              >
                Edit
              </button>
            )}
          </div>
        </div>
        
        
        {detailsLoading ? (
          "Loading..."
        ) : (
          <>
            <div className="uk-margin">
              <p className="uk-margin-remove uk-text-small">Name</p>
              <p className="uk-margin-remove">
                {details.first_name} {details.last_name}
              </p>
            </div>
            <div className="uk-margin">
              <p className="uk-margin-remove uk-text-small">Business Name</p>
              <p className="uk-margin-remove">{details.business_name}</p>
            </div>
            <div className="uk-margin">
              <p className="uk-margin-remove uk-text-small">Phone Number</p>
              <p className="uk-margin-remove">{details.phone_number}</p>
            </div>
            <div className="uk-margin">
              <p className="uk-margin-remove uk-text-small">Email</p>
              <p className="uk-margin-remove">{details.email}</p>
            </div>
            <div className="uk-margin">
              <p className="uk-margin-remove uk-text-small">Date Joined</p>
              <p className="uk-margin-remove">
                {date_formatter(details.date_added)}
              </p>
            </div>
          </>
        )}
      </div>
      {addModal && (
        <EditUserAccountModal
          set={setAddModal}
          value={details}
          getLatest={getLatest}
        />
      )}

      <div className="uk-grid-small" data-uk-grid>
        <div className="uk-width-expand">
          <h3>History</h3>
        </div>
        <div className="uk-width-auto">
          <CSVLink data={data} filename={`user_transactions_${new Date()}.csv`}>
            <button
              type="button"
              className="uk-button uk-button-primary uk-button-small uk-border-rounded"
            >
              Export to CSV
            </button>
          </CSVLink>
        </div>
      </div>

      <div className="uk-overflow-auto">
        {t_loading ? "Loading..." : <Table columns={columns} data={data} />}
      </div>
      <div style={{ marginTop: 150 }} />
    </div>
  );
};

UserPage.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(UserPage);
