import axios from "axios";
import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";
import { API_URL } from "../../config/keys";
import setAuthToken from "../../utils/setAuthToken";
import { SET_CURRENT_USER, SET_LOADING } from "./types";

export const loginUser = (token) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: true,
  });

  localStorage.setItem("jwtToken", token);

  // Set token to Auth header
  setAuthToken(token);

  // Decode token to get user data
  const decoded = jwt_decode(token);

  axios
    .post(`${API_URL}/admin/auth/get-role`, { id: decoded.id })
    .then((response) => {
      const user = {
        ...decoded,
        role: response.data.role,
      };

      dispatch({
        type: SET_CURRENT_USER,
        payload: user,
      });

      dispatch({
        type: SET_LOADING,
        payload: false,
      });
    })
    .catch((err) => {
      toast.error("Unable to login");
    });
};

export const logoutUser = () => (dispatch) => {
  if (localStorage.jwtToken) {
    localStorage.removeItem("jwtToken");
  }
  dispatch({
    type: SET_CURRENT_USER,
    payload: {},
  });
};
