import PropTypes from "prop-types";
import { connect } from "react-redux";
import Card from "../../components/card";
import TopCard, { TopCardLoader } from "../../components/top-card";
import { useParams, useHistory } from "react-router-dom";
import SkeletonLoader from "tiny-skeleton-loader-react";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../config/keys";
import toast from "react-hot-toast";
import date_formatter from "../../utils/dateFormatter";
import nFormatter from "../../utils/nFormatter";
import Table from "../../utils/table";
import React from "react";
import { EditPaymentChannelModal } from "./edit-payment-channel";
import isEmpty from "is-empty";
import { CSVLink } from "react-csv";

const Channel = ({ auth }) => {
  const [addModal, setAddModal] = useState(false);

  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [overview, setOverview] = useState({});
  const [overviewLoading, setOverviewLoading] = useState(true);
  const [transaction, setTransaction] = useState([]);
  const [transactionLoading, setTransactionLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const history = useHistory();

  useEffect(() => {
    axios
      .post(`${API_URL}/admin/api/get/payment-channel/`, { paymentLink: id })
      .then((response) => {
        setLoading(false);
        if (!isEmpty(response.data)) {
          setDetails(response.data);
        } else {
          history.replace("/payment-channel");
        }
      })
      .catch((error) => {
        toast.error("Unable to get payment channel details");
      });
  }, [id, history]);

  useEffect(() => {
    if (!!details.id) {
      axios
        .post(`${API_URL}/admin/api/transactions/overview`, {
          payment_channel: details.id,
        })
        .then((response) => {
          setOverview(response.data);
          setOverviewLoading(false);
        })
        .catch((err) => {
          toast.error("Unable to get transactions overview");
        });

      axios
        .post(`${API_URL}/admin/api/transactions/all`, {
          payment_channel: details.id,
        })
        .then((response) => {
          setTransaction(response.data);
          setTransactionLoading(false);
        })
        .catch((err) => {
          toast.error("Unable to fetch transactions history");
        });
    }
  }, [details]);

  useEffect(() => {
    if (!isEmpty(dateFrom) && !isEmpty(dateTo) && !!details.id) {
      setTransactionLoading(true);

      axios
        .post(`${API_URL}/admin/api/transactions/all`, {
          filter: {
            dateFrom,
            dateTo,
          },
          payment_channel: details.id,
        })
        .then((response) => {
          setTransaction(response.data);
          setTransactionLoading(false);
        })
        .catch((err) => {
          toast.error("Unable to fetch transactions history");
        });
    }
  }, [dateFrom, dateTo, details.id]);

  const getLatest = (e) => {
    setDetails({
      ...details,
      ...e,
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Customer",
        accessor: "customer",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Date",
        accessor: "date",
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    let dd = [];
    for (let i = 0; i < transaction.length; i++) {
      const e = transaction[i];
      dd.push({
        id: i,
        customer: `${e.first_name} ${e.last_name}`,
        amount: `N ${nFormatter(e.amount / 100)}`,
        date: date_formatter(e.paid_at),
      });
    }
    return dd;
  }, [transaction]);

  return (
    <div>
      {loading ? (
        <SkeletonLoader width={250} style={{ margin: "15px 0" }} />
      ) : (
        <div className="uk-grid-small" data-uk-grid>
          <div className="uk-width-expand">
            <h4>
              <span className="uk-text-bold">{details.channel_name},</span>{" "}
              {details.fullName}
            </h4>
          </div>
          <div className="uk-width-auto">
            {auth.user.role === "Super Admin" && (
              <button
                type="button"
                onClick={() => setAddModal(true)}
                className="uk-button uk-button-primary border-rounded"
              >
                Edit
              </button>
            )}
          </div>
        </div>
      )}

      <div
        className="uk-position-relative uk-visible-toggle uk-margin-top overflow-visible"
        tabIndex="-1"
        data-uk-slider="finite: true"
      >
        <ul className="uk-slider-items uk-grid-small uk-grid-match">
          {overviewLoading ? (
            <>
              <TopCardLoader />
              <TopCardLoader />
              <TopCardLoader />
              <TopCardLoader />
            </>
          ) : (
            <>
              <TopCard
                title="Today"
                value={overview.today_sum}
                count={overview.today_count}
              />
              <TopCard
                title="This week"
                value={overview.week_sum}
                count={overview.week_count}
              />
              <TopCard
                title="This Month"
                value={overview.month_sum}
                count={overview.month_count}
              />
              <TopCard
                title="All time"
                value={overview.alltime_sum}
                count={overview.alltime_count}
              />
            </>
          )}
        </ul>
      </div>

      <Card className="uk-margin-top">
        <div className="uk-grid-small" data-uk-grid>
          <div className="uk-width-expand">
            <h4>{details.channel_name} transactions</h4>
          </div>
          <div className="uk-width-auto">
            <CSVLink
              data={data}
              filename={`${
                details.payment_link
              }_transactions_${new Date()}.csv`}
            >
              <button
                type="button"
                className="uk-button uk-button-primary uk-button-small uk-border-rounded"
              >
                Export to CSV
              </button>
            </CSVLink>
          </div>
        </div>

        <div className="uk-margin-medium-top">
          <h5 className="uk-margin-small-bottom">Choose range</h5>
          <div className="uk-grid-small" data-uk-grid>
            <div className="uk-width-small">
              <input
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
                className="uk-input border-rounded"
              />
            </div>
            <div className="uk-width-small">
              <input
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
                className="uk-input border-rounded"
              />
            </div>
          </div>
        </div>

        {transactionLoading ? (
          <table className="uk-table uk-table-divider">
            <thead>
              <th>Customer</th>
              <th>Amount</th>
              <th>Date</th>
            </thead>

            <tbody>
              <tr>
                <td>
                  <SkeletonLoader />
                </td>
                <td>
                  <SkeletonLoader />
                </td>
                <td>
                  <SkeletonLoader />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <Table columns={columns} data={data} />
        )}
      </Card>
      {addModal && (
        <EditPaymentChannelModal
          set={setAddModal}
          value={details}
          getLatest={getLatest}
        />
      )}
    </div>
  );
};

Channel.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Channel);
