import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { logoWhite } from "../../images";
import styles from "../../styles/layout.module.css";
import { useEffect, useState } from "react";

const Sidebar = ({ open, auth }) => {
  const [sidebarMenu, setSidebarMenu] = useState([]);
  const location = useLocation();

  useEffect(() => {
    switch (auth.user.role) {
      case "Super Admin":
        setSidebarMenu([
          { name: "Dashboard", icon: "lucide:layout-dashboard", url: "/" },
          { name: "Users", icon: "mi:users", url: "/users" },
          {
            name: "Sub Admins",
            icon: "ic:outline-admin-panel-settings",
            url: "/subadmins",
          },
          {
            name: "Payment Channels",
            icon: "fluent:channel-arrow-left-16-regular",
            url: "/payment-channel",
          },
        ]);
        break;

      case "Admin":
        setSidebarMenu([
          { name: "Dashboard", icon: "lucide:layout-dashboard", url: "/" },
          { name: "Users", icon: "mi:users", url: "/users" },
          {
            name: "Payment Channels",
            icon: "fluent:channel-arrow-left-16-regular",
            url: "/payment-channel",
          },
        ]);
        break;

      case "Employee":
        setSidebarMenu([
          { name: "Dashboard", icon: "lucide:layout-dashboard", url: "/" },
        ]);
        break;

      default:
        break;
    }
  }, [auth.user.role]);

  return (
    <div className={`${styles.sidebarWrapper} ${!open ? styles.hidden : ""}`}>
      <aside className={`uk-background-secondary ${styles.sidebar}`}>
        <div>
          <div className={styles.logo}>
            <img src={logoWhite} alt="logo" />
          </div>

          <nav className="uk-margin-medium-top">
            <ul className="uk-nav">
              {sidebarMenu.map((menu, idx) => (
                <li key={idx}>
                  <Link
                    to={menu.url}
                    className={`${styles.navLink} ${
                      location.pathname === menu.url ||
                      location.pathname.startsWith(`${menu.url}/`)
                        ? styles.active
                        : ""
                    }`}
                  >
                    <Icon icon={menu.icon} className={styles.navIcon} />{" "}
                    {menu.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div>
          <nav className="uk-margin-medium-top">
            <ul className="uk-nav">
              <li>
                <Link to="/logout" className={`${styles.navLink}`}>
                  <Icon
                    icon="heroicons-outline:logout"
                    className={styles.navIcon}
                  />{" "}
                  Logout
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

Sidebar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Sidebar);
