import SuperAdminHomePage from "./super-admin/home";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EmployeeHome from "./employee/home";

const HomePage = ({ auth }) => {
  return (
    <>
      {auth.user.role === "Admin" || auth.user.role === "Super Admin" ? (
        <SuperAdminHomePage />
      ) : (
        <EmployeeHome />
      )}
    </>
  );
};

HomePage.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(HomePage);
