import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Icon } from "@iconify/react";
import styles from "../../styles/layout.module.css";

const HeaderComponent = ({ setSidebar, open, auth }) => {
  return (
    <nav className="uk-navbar-container uk-box-shadow-medium" data-uk-navbar>
      <div className="uk-navbar-left">
        <div className="uk-navbar-item" onClick={() => setSidebar(!open)}>
          <Icon icon="gg:menu" className={styles.menu} />
        </div>
      </div>
      <div className="uk-navbar-right">
        <div className="uk-navbar-item">
          <div className="uk-text-right">
            Welcome {auth.user.fullName}!
            <div className="uk-navbar-subtitle">
              <span className="uk-badge">{auth.user.role}</span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

HeaderComponent.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(HeaderComponent);
