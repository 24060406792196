import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { API_URL, USER_CLIENT_URL } from "../../config/keys";
import SkeletonLoader from "tiny-skeleton-loader-react";
import { AddPaymentChannelModal } from "./add-payment-channel";
import Table from "../../utils/table";
import React from "react";

const PaymentChannel = ({ auth }) => {
  const [addModal, setAddModal] = useState(false);

  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .post(`${API_URL}/admin/api/get/payment-channels/`)
      .then((response) => {
        setLoading(false);
        setChannels(response.data);
      })
      .catch((error) => {
        toast.error("Unable to get payment channels");
      });
  }, []);

  const getLatest = (data) => {
    setChannels([data, ...channels]);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Channel Name",
        accessor: "channel_name",
      },
      {
        Header: "Purpose",
        accessor: "purpose",
      },
      {
        Header: "Cashier",
        accessor: "cashier",
      },
      {
        Header: "Link",
        accessor: "linko",
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    let dd = [];
    for (let i = 0; i < channels.length; i++) {
      const e = channels[i];
      dd.push({
        id: i,
        channel_name: e.channel_name,
        purpose: e.purpose,
        cashier: e.fullName,
        linko: `${USER_CLIENT_URL}/${e.payment_link}`,
        link: `/payment-channel/${e.payment_link}`,
      });
    }
    return dd;
  }, [channels]);

  return (
    <div>
      <div className="uk-grid-small" data-uk-grid>
        <div className="uk-width-expand">
          <h3>Payment Channels</h3>
        </div>
        <div className="uk-width-auto">
          {auth.user.role === "Super Admin" && (
            <button
              type="button"
              onClick={() => setAddModal(true)}
              className="uk-button uk-button-primary border-rounded"
            >
              New
            </button>
          )}
        </div>
      </div>

      <div className="uk-margin-medium-top">
        {loading ? (
          <table className="uk-table uk-table-divider uk-table-large">
            <thead>
              <th>Channel Name</th>
              <th>Purpose</th>
              <th>Cashier</th>
              <th>Link</th>
            </thead>

            <tbody>
              <tr>
                <td>
                  <SkeletonLoader />
                </td>
                <td>
                  <SkeletonLoader />
                </td>
                <td>
                  <SkeletonLoader />
                </td>
                <td>
                  <SkeletonLoader />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div className="uk-overflow-auto">
            <Table
              columns={columns}
              data={data}
              className="uk-table uk-table-divider uk-table-hover uk-table-large"
            />
          </div>
        )}
      </div>

      {addModal && (
        <AddPaymentChannelModal set={setAddModal} getLatest={getLatest} />
      )}
    </div>
  );
};

PaymentChannel.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PaymentChannel);
